/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class ManagerService {
  static getRecurrenceClients(filters) {
    return useJwt.get('/manager/recurrence-clients', filters)
  }

  static getTotalClients(filters) {
    return useJwt.get('/manager/total-clients', filters)
  }
}
